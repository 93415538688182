var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"flex-column fill-height align-start",attrs:{"fluid":"","id":"totp"}},[_c('top-app-bar',{attrs:{"title":"Мобильные подтверждения"}}),_c('div',{staticClass:"flex-grow-1 d-flex flex-column position-relative align-center full-width justify-center"},[_c('v-card',{staticClass:"box-items-container mb-4",attrs:{"max-width":"525px","width":"100%"}},[_c('v-card-title',{staticClass:"pb-0"},[_vm._v("Генератор кодов")]),_c('v-card-text',[_c('v-subheader',{staticClass:"ml-n3"},[_vm._v("Введите shared_secret")]),_c('v-text-field',{staticClass:"full-width",attrs:{"filled":"","outlined":"","rules":[
            (val) =>
              !val || val.length === 28 || 'Неправильно введены данные',
          ],"hide-details":"","placeholder":"Пример: ZOZLO/CRYRs22sI+NWL0bf2Q2gs="},model:{value:(_vm.sharedSecret),callback:function ($$v) {_vm.sharedSecret=$$v},expression:"sharedSecret"}})],1),_c('v-card-actions',{staticClass:"px-4 mb-2"},[_c('gradient-btn',{attrs:{"disabled":_vm.sharedSecret.length !== 28,"borderless":"","block":"","large":"","color":"primary"},on:{"click":_vm.copyAuthCode}},[_vm._v("Сгенерировать")])],1)],1),_c('v-card',{staticClass:"box-items-container",attrs:{"max-width":"525px","width":"100%"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.steamTotpAcceptAll.apply(null, arguments)}}},[_c('v-card-title',{staticClass:"pb-0"},[_vm._v("Мобильные подтверждения")]),_c('v-card-text',[_c('v-subheader',{staticClass:"ml-n3"},[_vm._v("Введите steamId")]),_c('v-text-field',{staticClass:"full-width",attrs:{"filled":"","outlined":"","rules":[
              (val) =>
                !val || /\d{16,20}/.test(val) || 'Неправильно введены данные',
            ],"hide-details":"","placeholder":"Пример: 76561198831112267"},model:{value:(_vm.steamId),callback:function ($$v) {_vm.steamId=$$v},expression:"steamId"}}),_c('v-subheader',{staticClass:"ml-n3"},[_vm._v("Введите login")]),_c('v-text-field',{staticClass:"full-width",attrs:{"filled":"","outlined":"","rules":[(val) => !!val || 'Неправильно введены данные'],"hide-details":"","placeholder":"Пример: login123"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-subheader',{staticClass:"ml-n3"},[_vm._v("Введите password")]),_c('v-text-field',{staticClass:"full-width",attrs:{"filled":"","outlined":"","type":"password","rules":[(val) => !!val || 'Неправильно введены данные'],"hide-details":"","placeholder":"Пример: password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-subheader',{staticClass:"ml-n3"},[_vm._v("Введите shared_secret")]),_c('v-text-field',{staticClass:"full-width",attrs:{"filled":"","outlined":"","rules":[
              (val) =>
                !val || val.length === 28 || 'Неправильно введены данные',
            ],"hide-details":"","placeholder":"Пример: ZOZLO/CRYRs22sI+NWL0bf2Q2gs="},model:{value:(_vm.sharedSecret1),callback:function ($$v) {_vm.sharedSecret1=$$v},expression:"sharedSecret1"}}),_c('v-subheader',{staticClass:"ml-n3"},[_vm._v("Введите identity_secret")]),_c('v-text-field',{staticClass:"full-width",attrs:{"filled":"","outlined":"","rules":[
              (val) =>
                !val || val.length === 28 || 'Неправильно введены данные',
            ],"hide-details":"","placeholder":"Пример: ZOZLO/CRYRs22sI+NWL0bf2Q2gs="},model:{value:(_vm.identitySecret),callback:function ($$v) {_vm.identitySecret=$$v},expression:"identitySecret"}})],1),_c('v-card-actions',{staticClass:"px-4 mb-2"},[_c('gradient-btn',{attrs:{"loading":_vm.loading,"disabled":!_vm.username || !_vm.password || _vm.sharedSecret1.length !== 28 || !/\d{16,20}/.test(_vm.steamId) || _vm.identitySecret.length !== 28,"type":"submit","borderless":"","block":"","large":"","color":"primary"}},[_vm._v("Подтвердить все")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }