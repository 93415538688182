
import { Component, Vue } from "vue-property-decorator";
import TopAppBar from "@/components/base/TopAppBar.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";
import gql from "graphql-tag";

const SteamTotp = require("steam-totp");

@Component({
  components: {
    TopAppBar,
    GradientBtn,
  },
})
export default class Totp extends Vue {
  sharedSecret: string = "";
  steamId: string = "";
  username: string = "";
  password: string = "";
  sharedSecret1: string = "";
  identitySecret: string = "";
  loading: boolean = false;

  async steamTotpAcceptAll() {
    this.loading = true;
    await this.$apollo
      .mutate({
        mutation: gql`
          mutation ($input: SteamTotpAccountInput!) {
            steamTotpAcceptAll(input: $input)
          }
        `,
        variables: {
          input: {
            username: this.username,
            steamId: this.steamId,
            password: this.password,
            sharedSecret: this.sharedSecret1,
            identitySecret: this.identitySecret,
          },
        },
      })
            .then(() => {
              this.$notify({
                type: "success",
                text: `Успех`
              })
            })
      .catch((e) =>
        this.$notify({
          type: "error",
          text:
            e.message.indexOf("ThrottlerException") !== -1
              ? `Это действие можно делать не чаще, чем 1 раз в 30 секунд`
              : `Перепроверьте введенные Вами данные.`,
        })
      );
    this.loading = false;
  }

  generateAuthCode() {
    return SteamTotp.generateAuthCode(this.sharedSecret);
  }

  copyAuthCode() {
    const code = this.generateAuthCode();
    this.$copyText(code);
    this.$notify({
      text: `Код ${code} успешно скопирован`,
      type: "info",
    });
  }
}
